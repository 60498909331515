$color-accent: rgba(189, 79, 79, 0.87);

.notify-icon-badge[data-count]:after {
  position: absolute;
  @media (min-width: 991.98px) {
    right: -10%;
  }
  top: 1%;
  content: attr(data-count);
  font-size: 60%;
  padding: .6em;
  border-radius: 999px;
  line-height: .75em;
  color: white;
  background: $color-accent;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}

.notify-icon-badge[data-count='0']:after {
  content: none;
}
@media (min-width: 991.98px) {
  .notifications-label {
    display: none;
  }
}

//--------------------------------------

.notify-drop {
  min-width: 330px;
  background-color: #fff;
  max-height: 360px;
}
.notify-drop .notify-drop-title {
  border-bottom: 1px solid #e2e2e2;
  padding: 5px 0px 10px 0px;
}
.notify-drop .drop-content {
  max-height: 300px;
  overflow-y: scroll;
}
.notify-drop .drop-content::-webkit-scrollbar-track
{
  background-color: #F5F5F5;
}

.notify-drop .drop-content::-webkit-scrollbar
{
  width: 8px;
  background-color: #F5F5F5;
}

.notify-drop .drop-content::-webkit-scrollbar-thumb
{
  background-color: #ccc;
}
.notify-drop .drop-content li {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 0px;
}
.notify-drop .drop-content .notify-unread {
  background-color: #f5f5f5;
}
.notify-drop .drop-content li:after {
  content: "";
  clear: both;
  display: block;
}
.notify-drop .drop-content li:hover {
  background-color: #fcfcfc;
}
.notify-drop .drop-content .notify-unread:hover {
  background-color: #ededed;
}
/*
.notify-drop .drop-content li .notify-img {
  float: left;
  display: inline-block;
  width: 45px;
  height: 45px;
  margin: 0px 0px 8px 0px;
}
*/
.notify-drop .rIcon {
  float: right;
  color: #999;
}
.notify-drop .rIcon:hover {
  color: #333;
}
.notify-drop .drop-content li a {
  font-size: 12px;
  font-weight: normal;
}
.notify-drop .drop-content li {
  font-size: 11px;
}
.notify-drop .drop-content li hr {
  margin: 5px 0;
  width: 70%;
  border-color: #e2e2e2;
}
.notify-drop .drop-content .pd-l0 {
  padding-left: 0;
}
.notify-drop .drop-content li p {
  font-size: 11px;
  color: #666;
  font-weight: normal;
  margin: 3px 0;
}
.notify-drop .drop-content li p.notify-btn {
  font-size: 10px;
  font-weight: 600;
  top: -6px;
  margin: 8px 2px 0px 2px;
  padding: 0px 8px;
  border: 1px solid #e2e2e2;
  position: relative;
  background-image: linear-gradient(#fff,#f2f2f2);
  display: inline-block;
  border-radius: 2px;
  color: $color-accent;
}
.notify-drop .drop-content li p.notify-btn:hover {
  background-image: linear-gradient(#fff,#fff);
}
.notify-drop .notify-drop-footer {
  border-top: 1px solid #e2e2e2;
  bottom: 0;
  position: relative;
  padding: 8px 15px;
}
.notify-drop .notify-drop-footer a {
  color: #777;
  text-decoration: none;
}
.notify-drop .notify-drop-footer a:hover {
  color: #333;
}
.notify-drop .row{
  margin: 0;
}
.notify-read-all {
  font-size: 12px;
  padding-right: 22px; //15+7
  padding-left: 8px; //15-7
}
.notify-drop{
  padding-bottom: 0;
}
.notify-drop .drop-content > li.notify-more{
  padding-bottom: 10px;
}
.rIcon svg{
  margin-right: 0px;
}
.notify-drop .drop-content .notify-img{
  opacity: 0.5;
}
.notify-drop .drop-content > li.notify-more.disabled{
  opacity: 0.4;
}
.notify-drop .drop-content li .notify-img {
  margin-top: 7px;
}
.notify-time{
  position: absolute;
  bottom: 0;
}
.notify-notification>.row{
  min-height: 45px;
}
.clickable{
  cursor: pointer;
}
a.hyperlink-no-style{
  color: inherit;
  text-decoration: inherit;
}
