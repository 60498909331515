$color-primary: #d6d6d6;
$color-secondary: #e7e7e7;
$color-tertiary: #f5f5f5;


nav {
    background-color: whitesmoke;
    border-bottom: 1px solid $color-primary;
}

body {
    margin-top: 80px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.full-width{
    width: 100%;
}

.half-width{
    width: 49%;
}

.btn{
    border: 1px solid $color-primary;
}

.jumbotron{
    padding: 1rem;
    background-color: $color-tertiary;
    border: 1px solid $color-primary;
}

.dropdown-menu svg{
    margin-right: 10px;
}


.form-control::-webkit-input-placeholder {
    opacity: 0.5;
}

.form-control::-moz-placeholder {
    opacity: 0.5;
}

.form-control::placeholder {
    opacity: 0.5;
}

h1{
    border-bottom: 1px solid $color-primary;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.block-footer{
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.card{
    margin-bottom: 20px;
}

.table-calendar{
    margin-bottom: 30px;
    margin-top: 10px;
}

.table-logs td{
    padding: 0.25rem;
}

.padding-0{
    padding: 0;
}

/*table.table-settings{
    border: 1px solid #d6d6d6 !important;
}

.table-settings>tr, .table-settings>td{
    border: 1px solid whitesmoke !important;
}*/


table.table-settings.table-bordered > thead > tr > th{
    border:1px solid #d6d6d6;
}
table.table-settings.table-bordered > tbody > tr > td{
    border:1px solid #d6d6d6;
}
table.table-settings.table-bordered{
    border:1px solid #d6d6d6;
}

.btn-sm{
    min-width: 32px;
}

.highlight-currency{
    background-color: #f1f1f1;
}